<template>
   <v-card class="mx-auto mb-10">
    <v-card-title class="font-weight-bold">
        On Air
    </v-card-title>
    <v-divider />
    <div class="skeleton-list-item w-full" v-if="hourlyReportLoading">
        <v-skeleton-loader  :loading="true" type="image"></v-skeleton-loader>
    </div>
    <v-simple-table class="pa-4 mt-2" v-if="!hourlyReportLoading">
      <template v-slot:default>
        <tbody class="simple-table-borders">
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-right border-left">Once</td>
                <td class="text-right border-right font-weight-bold">No. of donations added to the tally</td>
                <td class="px-15 text-center border-right">
                    {{OnAirSummary && OnAirSummary.once_count ? OnAirSummary.once_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations added to the tally</td>
                <td class="px-15 text-center border-right">
                    {{OnAirSummary && OnAirSummary.once_amount ? OnAirSummary.once_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-top border-right border-left">Recurring</td>
                <td class="text-right border-right border-top border-top font-weight-bold">No. of donations added to the tally</td>
                <td class="px-15 text-center border-right border-top">
                    {{OnAirSummary && OnAirSummary.rec_count ? OnAirSummary.rec_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations received</td>
                <td class="px-15 text-center border-right">
                    {{OnAirSummary && OnAirSummary.rec_amount ? OnAirSummary.rec_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of new Donors</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.new_donors ? OnAirSummary.new_donors : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentive Donations</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.incentive_donation ? OnAirSummary.incentive_donation : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentives</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.incentive_count ? OnAirSummary.incentive_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No. of donations added the inspiration fund</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.inspiration_count ? OnAirSummary.inspiration_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ value of donations added to the inspiration fund</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.inspiration_amount ? OnAirSummary.inspiration_amount : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">Total no. of donations</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.donation_count ? OnAirSummary.donation_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ Total value of donations</td>
                <td class="px-15 text-center border-right border-none">
                    {{OnAirSummary && OnAirSummary.donation_amount ? OnAirSummary.donation_amount : 0}}
                </td>
            </tr>
        </tbody>
      </template>
    </v-simple-table> 
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['hourlyReportLoading', 'OnAirSummary'])
  }
}
</script>
